import {
  SubscriptionListItem, SubsctiptionFeature,
} from '../../screens/profile/ProfileSubscriptionScreen/hooks';
import {apiMethod, APIService} from '../APIService';

class SubscriptionAPI extends APIService {
  fetchSubscriptionList = apiMethod<SubscriptionListItem[]>(
    () => this.fetcher.request({
      method: 'get',
      url: `/api/subscription/list/`,
    }),
  );
  fetchSubscriptionInfo = apiMethod(
    () => this.fetcher.request({
      method: 'get',
      url: `/api/subscription/info/`,
    }),
  );
  fetchFeaturesList = apiMethod(
    () => this.fetcher.request<SubsctiptionFeature[]>({
      method: 'get',
      url: `/api/subscription/features-list/`,
    }),
  );
  activateSubscription = apiMethod(
    (id: number) => this.fetcher.request({
      method: 'post',
      url: `/api/subscription/activate/`,
      data: {
        subscription_id: id,
      },
    }),
  );
  cancelSubscription = apiMethod(
    () => this.fetcher.request({
      method: 'post',
      url: `/api/subscription/cancel/`,
    }),
  );
}

export {SubscriptionAPI};
