import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {SubscriptionAPI} from 'services/SubscriptionAPI';
import {
  getProSubscription,
  setProSubscription,
  Subscription,
  SubscriptionStatus,
} from 'store/subscription/reducer';
import {APIResponseStatus} from '../common/types';

// Subscription info also refreshed in screens/Root/index, each 5 minutes
export const useSubscription = (): [
    null | Subscription,
  boolean,
  boolean
] => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const {formatMessage} = useIntl();

  const [isLoading, setLoading] = useState(false);
  const subscription = useSelector(getProSubscription);
  const subApi = new SubscriptionAPI();

  useEffect(() => {
    if (subscription === null && !isLoading) {
      setLoading(true);
      subApi.fetchSubscriptionInfo()
        .then(response => {
          if (response.status === APIResponseStatus.Ok) {
            dispatch(setProSubscription(response.data));
          }
        })
        .catch(error => {
          snackbar.enqueueSnackbar(
            formatMessage({id: 'errors.general'}),
            {key: 'use_subscription'},
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [subscription]);

  const isActive = !!(subscription && subscription.status === SubscriptionStatus.ACTIVE);

  return [
    subscription,
    isActive,
    isLoading,
  ];
};
