import {AppState} from "../index";

export const globalConfigSelector = (state: AppState) => state.globalConfig.data;
export const referralProgramActiveSelector = (state: AppState) => state.globalConfig.data?.referralProgram?.active;
export const referralProgramRegisterSelector = (state: AppState) => state.globalConfig.data?.referralProgram?.register;
export const referralProgramPaymentsSelector = (state: AppState) => state.globalConfig.data?.referralProgram?.payments;
export const referralProgramRegisterBonus = (state: AppState) => state.globalConfig.data?.referralProgram?.registerBonus;
export const referralProgramPaymentBonus= (state: AppState) => state.globalConfig.data?.referralProgram?.paymentBonus;

export const olxImportAllowedSelector = (state: AppState) => state.globalConfig.data?.olxImport?.allowed;
export const newRieltorBonusSelector = (state: AppState) => state.globalConfig.data?.newRieltorBonus;
export const newOwnerBonusSelector = (state: AppState) => state.globalConfig.data?.newOwnerBonus;
export const paymentBonusesSelector = (state: AppState) => state.globalConfig.data?.paymentBonuses;
export const newOwnerBonusStandardDateSelector = (state: AppState) => state.globalConfig.data?.newOwnerBonusStandardDate;
export const newRieltorBonusStandardAmountSelector = (state: AppState) => state.globalConfig.data?.newRieltorBonusStandardDate;

export const imageUploaderConfigSelector = (state: AppState) => state.globalConfig.data?.offers.imageUpload;
export const imageAvatarConfigSelector = (state: AppState) => state.globalConfig.data?.avatar;

export const vatPaymentConfigSelector = (state: AppState) => state.globalConfig.data?.vatPercent;
export const vatAlertConfigSelector = (state: AppState) => state.globalConfig.data?.showVatAlert;
export const supportHoursConfigSelector = (state: AppState) => state.globalConfig.data?.supportHours;
export const freeOfferProcessorConfigSelector = (state: AppState) => state.globalConfig.data?.freeOfferProcessor;