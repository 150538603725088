import {makeStyles, Theme} from '@material-ui/core';
import {Nullable} from 'common/types';
import {ResizedImage} from 'components/ResizedImage';
import React, {FunctionComponent} from 'react';

export interface UserAvatarProps {
  imageKey?: Nullable<string>;
  rounded?: boolean;
  round?: boolean;
  width: number;
  height: number;
  className?: string;
}

const useStyles = makeStyles<Theme, UserAvatarProps>(theme => ({
  placeholder: {
    width: props => props.width,
    height: props => props.width,
    borderRadius: props => (props.rounded ? theme.shape.borderRadius : 0),
  },
}));

const UserAvatar: FunctionComponent<UserAvatarProps> = (
  props: UserAvatarProps
) => {
  const {imageKey, width, height, rounded, round, className} = props;
  const classes = useStyles(props);
  if (!imageKey) {
    return (
      <img
        className={`${classes.placeholder} ${props.className}`}
        src={require('screens/common/assets/placeholder.svg')}
        alt=""
      />
    );
  }

  return (
    <ResizedImage
      imageKey={imageKey}
      width={width}
      height={height}
      rounded={rounded}
      round={round}
      className={className}
    />
  );
};

export default UserAvatar;
