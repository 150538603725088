import {RoleId, UserLevelStatus} from 'common/types';
import {
  Dashboard,
  Deals, ExternalUrl,
  MyWebsite,
  Offers,
  Partners,
  ProfileSettings,
  ProfileStats, Unions,
} from 'components/Icons';
import {Badge} from 'components/UserMenu/UserMenuItem';
import React from 'react';
type Callback = (props: any) => boolean;
export interface UserMenuConfigItem {
  uid: string;
  roles: RoleId[];
  visible?: Callback;
  url: string;
  label: string;
  icon: JSX.Element;
  iconEnd?: JSX.Element;
  new: boolean;
  badge?: (props: UserMenuBadge) => JSX.Element;
  isProFeature?: boolean | Callback; // FIXME Let's backend decide, if menu item (route) require PRO feature
}

export interface UserMenuBadge {
  activeOffersCount: number;
  partnersConfirmed?: number;
  partnersRequests?: number;
  agentsRequests?: number;
  referralCount?: number;
  referralEnabled?: boolean;
}

export const UserMenuConfig: UserMenuConfigItem[] = [
  {
    uid: 'dashboard',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin],
    url: '/dashboard',
    label: 'user_profile_menu.dashboard',
    icon: <Dashboard/>,
    new: false,
  },
  {
    uid: 'offers_management',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin, RoleId.Owner],
    url: '/offers/management',
    label: 'user_profile_menu.my_offers',
    icon: <Offers/>,
    new: false,
    badge: (props: UserMenuBadge) => {
      return <Badge value={props.activeOffersCount}/>;
    },
  },
  {
    uid: 'deals',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin],
    url: '/deals',
    label: 'user_profile_menu.my_deals',
    icon: <Deals/>,
    new: false,
  },
  {
    uid: 'partners',
    roles: [RoleId.Realtor],
    url: '/partners',
    label: 'user_profile_menu.partners',
    icon: <Partners/>,
    new: false,
    badge: (props: UserMenuBadge) => {
      if (props.partnersRequests && props.partnersRequests > 0) {
        return <Badge value={`+${props.partnersRequests}`}/>;
      }

      return <Badge value={props.partnersConfirmed}/>;
    },
  },
  {
    uid: 'unions',
    roles: [],
    visible: (props: any) => {
      if (!props.user) {
        return false;
      }
      return !!(props.user.asnu || props.user.sfnu);
    },
    url: '/unions',
    label: 'user_profile_menu.unions',
    icon: <Unions/>,
    new: true,
  },
  {
    uid: 'agents',
    roles: [RoleId.AgencyAdmin],
    url: '/profile/agents',
    label: 'user_profile_menu.agents',
    icon: <Partners/>,
    new: false,
    badge: (props: UserMenuBadge) => {
      if (props.agentsRequests && props.agentsRequests > 0) {
        return <Badge value={`+${props.agentsRequests}`}/>;
      }

      return <></>;
    },
  },
  // {
  //   uid: 'referral',
  //   roles: [RoleId.Realtor],
  //   url: '/referrals',
  //   label: 'user_profile_menu.referrals',
  //   icon: <Partners/>,
  //   new: true,
  //   badge: (props: UserMenuBadge) => {
  //     return <Badge value={props.referralCount}/>;
  //   },
  //   visible: (props: any) => {
  //     return !!props.referralEnabled;
  //   }
  // },

  // {
  //   uid: 'manifesto',
  //   roles: [RoleId.Realtor],
  //   url: '/rieltor-manifest',
  //   label: 'user_profile_menu.rieltor_manifest',
  //   icon: <Rieltor/>,
  //   new: false,
  // },
  {
    uid: 'profile',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin, RoleId.Owner],
    url: '/profile/stats',
    label: 'user_profile_menu.stats',
    icon: <ProfileStats/>,
    new: false,
  },
  {
    uid: 'my_website',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin],
    url: '',
    label: 'user_profile_menu.my_site',
    icon: <MyWebsite/>,
    new: false,
    iconEnd: <ExternalUrl/>
  },
  {
    uid: 'pro_subscription',
    roles: [RoleId.Realtor],
    url: '/profile/subscription',
    label: 'user_profile_menu.pro_subscription',
    icon: <svg width="23" height="24" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1.5" width="21" height="13" rx="2.40541" stroke="currentColor" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9821 5.72461C18.3993 5.72461 19.829 6.3302 19.829 7.99715C19.829 9.67035 18.3993 10.2759 16.9821 10.2759C15.5649 10.2759 14.1414 9.67035 14.1414 7.99715C14.1414 6.3302 15.5649 5.72461 16.9821 5.72461ZM16.9821 9.27702C17.8437 9.27702 18.3244 8.79629 18.3244 7.99715C18.3244 7.20426 17.8437 6.72353 16.9821 6.72353C16.1268 6.72353 15.6398 7.20426 15.6398 7.99715C15.6398 8.79629 16.1268 9.27702 16.9821 9.27702ZM12.2371 10.1825L11.3505 8.76524H10.1893V10.1825H8.70962V5.81218H11.8188C13.0237 5.81218 13.6855 6.36783 13.6855 7.26062C13.6855 7.9224 13.3484 8.34695 12.7927 8.5717L13.8291 10.1825H12.2371ZM10.1893 6.82359V7.76632H11.6065C12.0435 7.76632 12.1809 7.54157 12.1809 7.26062C12.1809 6.97343 11.9998 6.82359 11.6065 6.82359H10.1893ZM8.08483 7.29184C8.08483 6.39905 7.47923 5.81218 6.27429 5.81218H3.17139V10.1825H4.65104V8.82143H6.09947C7.30442 8.82143 8.08483 8.39065 8.08483 7.29184ZM6.5802 7.29184C6.5802 7.62897 6.38666 7.82251 5.92466 7.82251H4.65104V6.82359H5.92466C6.36169 6.82359 6.5802 6.94846 6.5802 7.29184Z" fill="currentColor"/>
    </svg>,
    new: true,
    visible: (props: any) => {
      if (!props.user) {
        return false;
      }

      // FIXME For alpha test we release PRO subscription only to Platinum rieltors
      return !!(props.user.verificated && props.user.roleId === RoleId.Realtor &&
        (props.user.levelStatus >= UserLevelStatus.White));
    },
  },
  {
    uid: 'profile_summary',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin, RoleId.Owner],
    url: '/profile/summary',
    label: 'user_profile_menu.profile',
    icon: <ProfileSettings/>,
    new: false,
  },
];
