import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import {IconClose} from '../../../components/Icons';
import {rieltor} from '../../../styles/theme';
import Typo from '../../../components/Typo';
import {FormattedMessage} from 'react-intl';
import {ButtonPurple} from '../../../components/Buttons';
import RouterLink from '../../common/RouterLink';
import {LocalStorageKeys, RoleId} from '../../../common/types';
import {useCurrentUser} from '../../../hooks/user';
import {createProfileEditUrl} from '../../../utils/navigation';
import {useLocalStorage} from '@rehooks/local-storage';
import {isToday} from 'date-fns';

const useStyles = makeStyles((theme) => ({
  popupWrapper: {
    '& .MuiDialog-paper': {
      maxWidth: '650px !important',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 30px',
    },
  },
}));
const ForceAvatarAlert = () => {
  const classes = useStyles();

  const user = useCurrentUser();
  const [open, setOpen] = useState(false);

  const [
    lastOpenDate,
    setLastOpenDate
  ] = useLocalStorage<string|null>(LocalStorageKeys.ForceAvatarAlert, null);

  let today = false;
  if (lastOpenDate !== null) {
    today = isToday(Date.parse(lastOpenDate));
  }

  useEffect(() => {
    if (!today && user && !user.avatar && (user.roleId === RoleId.Realtor)) {
      setOpen(true);
      setLastOpenDate((new Date()).toString());
    }
  }, [today]);

  if (user === null) {
    return null;
  }

  return (
      <Dialog
          className={classes.popupWrapper}
          open={open}
          onClose={() => setOpen(false)}
      >
        <DialogTitle style={{padding: '8px 16px'}}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <div>&nbsp;</div>

            <IconClose
                style={{cursor: 'pointer', color: rieltor.gray4}}
                onClick={() => setOpen(false)}
            />
          </Box>
        </DialogTitle>

        <DialogContent>
          <div>
            <Typo variant={'title0'}>
              <FormattedMessage id={'alerts.force_avatar_alert.title'}/>
            </Typo>
          </div>

          <p style={{margin: '30px 0', lineHeight: '28px', fontFamily: rieltor.fontFamily, fontSize: '16px'}}>
            <FormattedMessage id={'alerts.force_avatar_alert.text'}/>
          </p>
        </DialogContent>

        <DialogActions style={{justifyContent: 'center', padding: '0 16px 20px 16px'}}>
          <ButtonPurple
              // @ts-ignore
              component={RouterLink}
              to={createProfileEditUrl({}) + '#profile_edit_info'}
          >
            <FormattedMessage id={'alerts.force_avatar_alert.button'}/>
          </ButtonPurple>
        </DialogActions>
      </Dialog>
  );
};

export default ForceAvatarAlert;