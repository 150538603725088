import {Box, Container, Divider, makeStyles, Theme} from '@material-ui/core';
import {Facebook, Instagram} from '@material-ui/icons';
import cx from 'classnames';
import {
  SOCIAL_FACEBOOK,
  SOCIAL_INSTAGRAM,
  SOCIAL_VIBER,
} from 'common/constants';
import {Viber} from 'components/Icons';
import Logo from 'components/Logo';
import {SupportFooter} from 'components/UserMenu/Support';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {rieltor} from 'styles/theme';
import {
  createLegacyInterfaceUrl,
  createRieltorManifestUrl,
} from 'utils/navigation';
import {useCurrentUser} from '../../../hooks/user';
import {RoleId} from '../../../common/types';

const CURRENT_YEAR = new Date().getFullYear();

export interface FooterProps {
}

const useStyles = makeStyles(({breakpoints}: Theme) => ({
  root: {
    background: rieltor.mainGradient,
    justifyContent: 'center',
    display: 'flex'
  },
  footerContainer: {
    paddingTop: '87px',
    [breakpoints.down('sm')]: {
      paddingTop: '24px',
    }
  },
  socialLinksContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '35px',
    marginTop: '37px',
  },
  footerTopContainer: {
    display: 'grid',
    gridTemplateColumns: '2.3fr 1fr 1fr 1fr 1fr',
    gridTemplate: '"rieltor list list list list"',
    gap: '32px',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplate: '"rieltor rieltor" "list list"'
    }
  },
  footerColumn: {

  },
  footerColumnDouble: {
    gridArea: 'rieltor',
  },
  footerLogo: {
    width: '213px',
    height: '64px',
  },
  list: {
    padding: 0,
  },
  footerListItem: {
    listStyle: 'none',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      fontSize: '14px',
      lineHeight: '30px',
    }
  },
  footerTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '20px',
    margin: '0 0 16px',
    color: '#fff',
  },
  footerBottomContainer: {
    display: 'grid',
    gridTemplateColumns: '2.3fr 3fr',
    alignItems: 'center',
    padding: '46px 0',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '24px',
    }
  },
  footerLogos: {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '60px',
  },
  footerCopyright: {
    display: 'flex',
    padding: '37px 0',
    justifyContent: 'space-between',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },
}));

const Footer = (props: FooterProps) => {
  const classes = useStyles();
  const user = useCurrentUser();

  if (user === null) {
    return null;
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column" alignItems={'center'}>
      <Container maxWidth={'lg'} className={classes.footerContainer}>
        <div className={classes.footerTopContainer} id="footer-container">
          <div className={cx(classes.footerColumn, classes.footerColumnDouble)}>
            <Logo logoType={'big'} imageClassName={classes.footerLogo}/>

            <div className={classes.socialLinksContainer}>
              <a href={SOCIAL_VIBER}>
                <Viber style={{color: 'white', width: 42, height: 42}}/>
              </a>

              <a href={SOCIAL_FACEBOOK}>
                <Facebook style={{color: 'white', width: 42, height: 42}}/>
              </a>

              <a href={SOCIAL_INSTAGRAM}>
                <Instagram style={{color: 'white', width: 42, height: 42}}/>
              </a>
            </div>
          </div>

          <div className="footer-column">
            <div className={classes.footerTitle}>
              <FormattedMessage id="footer.projectTitle"/>
            </div>
            <ul className={classes.list}>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/pages/about/')}>
                  <FormattedMessage id="footer.about"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/pages/terms/')}>
                  <FormattedMessage id="footer.terms"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/tariffs/')}>
                  <FormattedMessage id="footer.tariffs"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/faq/')}>
                  <FormattedMessage id="footer.faq"/>
                </a>
              </li>
              {
                (user.roleId === RoleId.Realtor) && <li className={classes.footerListItem}>
                  <a href={createRieltorManifestUrl({})}>
                    <FormattedMessage id="rieltor_manifest_screen.text1_link"/>
                  </a>
                </li>
              }
            </ul>
          </div>

          <div className="footer-column">
            <div className={classes.footerTitle}>
              <FormattedMessage id="footer.specialistTitle"/>
            </div>
            <ul className={classes.list}>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/users/')}>
                  <FormattedMessage id="footer.rieltors"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/firms/')}>
                  <FormattedMessage id="footer.firms"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/unions/')}>
                  <FormattedMessage id="footer.unions"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/top100/')}>
                  <FormattedMessage id="footer.top100"/>
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <div className={classes.footerTitle}>
              <FormattedMessage id="footer.realEstateTitle"/>
            </div>
            <ul className={classes.list}>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/flats-sale/')}>
                  <FormattedMessage id="footer.flats"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/rooms-sale/')}>
                  <FormattedMessage id="footer.rooms"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/houses-sale/')}>
                  <FormattedMessage id="footer.houses"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/commercials-sale/office/')}>
                  <FormattedMessage id="footer.offices"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/areas-sale/')}>
                  <FormattedMessage id="footer.areas"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/commercials-sale/')}>
                  <FormattedMessage id="footer.commercials"/>
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <div className={classes.footerTitle}>&nbsp;</div>
            <ul className={classes.list}>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/cities/')}>
                  <FormattedMessage id="footer.flats_by_city"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/cities/houses-sale/')}>
                  <FormattedMessage id="footer.houses_by_city"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/districts/')}>
                  <FormattedMessage id="footer.districts"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/streets/')}>
                  <FormattedMessage id="footer.streets"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/orients/')}>
                  <FormattedMessage id="footer.orients"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/novostroyki/')}>
                  <FormattedMessage id="footer.new_buildings"/>
                </a>
              </li>
              <li className={classes.footerListItem}>
                <a href={createLegacyInterfaceUrl('/cottages/')}>
                  <FormattedMessage id="footer.cottages"/>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={classes.footerBottomContainer}>
          <div className={classes.footerLogos}>
            <a href="https://lun.ua/uk/новобудови-києва" title="Каталог новобудов ЛУН" target="_blank">
              <img
                className="footer-logos__logo"
                src={require('./images/lun.svg')}
                width="62px"
                height="26px"
                alt="Каталог ЛУН"
              />
            </a>

            <a href="https://flatfy.ua/uk/продаж-квартир-київ" title="Пошуковик нерухомості Flatfy" target="_blank">
              <img
                className="footer-logos__logo"
                src={require('./images/flatfy.svg')}
                width="64px"
                height="26px"
                alt="Flatfy: продаж квартир"
              />
            </a>

            <img
              className="footer-logos__logo"
              src={require('./images/mastercard.svg')}
              width="37px"
              height="26px"
              alt="Mastercard logo"
            />
            <img
              className="footer-logos__logo"
              src={require('./images/visa.svg')}
              width="47px"
              height="26px"
              alt="Visa logo"
            />
          </div>

          <SupportFooter/>
        </div>

        <Divider style={{backgroundColor: '#fff'}}/>

        <div className={classes.footerCopyright}>
          <FormattedMessage id="footer.copyright" values={{currentYear: CURRENT_YEAR}}/>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
