import {AppState} from '../index';

export enum SubscriptionStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export interface Subscription {
  id: number;
  status: SubscriptionStatus;
  name: string;
  isCancelled: boolean;
  validTo: string;
  price: number;
  priceInUah: boolean;
}

interface State {
  subscription: null | Subscription;
}

const initState: State = {
  subscription: null,
};

const FETCH_SUBSCRIPTION_INFO = '@subscription/info';

interface FetchSubscriptionInfo {
  type: typeof FETCH_SUBSCRIPTION_INFO;
  data: null | Subscription;
}

export type SubscriptionAction =
  FetchSubscriptionInfo;

export const subscriptionReducer = (
  state: State = initState,
  action: SubscriptionAction,
) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_INFO:
      return {
        ...state,
        subscription: action.data,
      };

    default:
      return state;
  }
};

export const setProSubscription = (subscription: null | Subscription) => {
  return {
    type: FETCH_SUBSCRIPTION_INFO,
    data: subscription,
  };
};

export const getProSubscription = (state: AppState) =>
  state.subscription.subscription;
